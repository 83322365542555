import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

const Seo = ({ title, description, image, article }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata;

  const Seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet title={Seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={Seo.description} />
      <meta name="image" content={Seo.image} />
      <html lang="en" />

      {Seo.url && <meta property="og:url" content={Seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {Seo.title && <meta property="og:title" content={Seo.title} />}

      {Seo.description && (
        <meta property="og:description" content={Seo.description} />
      )}

      {Seo.image && <meta property="og:image" content={Seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {Seo.title && <meta name="twitter:title" content={Seo.title} />}

      {Seo.description && (
        <meta name="twitter:description" content={Seo.description} />
      )}

      {Seo.image && <meta name="twitter:image" content={Seo.image} />}

      <script type="application/ld+json">
        {`
          {
              "@context": "https://schema.org/",
              "@type": "Person",
              "name": "Nathaniel Noyd",
              "givenName": "Nathaniel",
              "familyName": "Noyd",
              "image": "https://www.nathanielnoyd.com/images/nn.png",
              "url": "https://www.nathanielnoyd.com",
              "sameAs": [
                  "https://www.linkedin.com/in/nathanielnoyd",
                  "https://twitter.com/nathanielnoyd",
                  "https://angel.co/u/nathanielnoyd"
              ],
              "alumniOf": [
                  {
                  "@type": "CollegeOrUniversity",
                  "name": "New York University",
                  "sameAs": [
                      "https://www.nyu.edu",
                      "https://en.wikipedia.org/wiki/New_York_University",
                      "https://www.wikidata.org/wiki/Q49210"
                      ]
                  },
                  {
                      "@type": "CollegeOrUniversity",
                      "name": "Georgetown University",
                      "sameAs": [
                          "https://www.georgetown.edu",
                          "https://en.wikipedia.org/wiki/Georgetown_University",
                          "https://www.wikidata.org/entity/Q333886"
                      ]
                  },
                  {
                      "@type": "CollegeOrUniversity",
                      "name": "McDonough School of Business",
                      "sameAs": [
                          "https://msb.georgetown.edu/",
                          "https://en.wikipedia.org/wiki/McDonough_School_of_Business",
                          "https://www.wikidata.org/entity/Q3303635"
                      ]
                  }
              ],
              "affiliation": [
                  {
                      "@type": "CollegeOrUniversity",
                      "name": "New York University",
                      "sameAs": [
                          "https://www.nyu.edu",
                          "https://en.wikipedia.org/wiki/New_York_University",
                          "https://www.wikidata.org/wiki/Q49210"
                      ]
                  },
                  {
                      "@type": "CollegeOrUniversity",
                      "name": "Georgetown University",
                      "sameAs": [
                          "https://www.georgetown.edu",
                          "https://en.wikipedia.org/wiki/Georgetown_University",
                          "https://www.wikidata.org/entity/Q333886"
                      ]
                  },
                  {
                      "@type": "CollegeOrUniversity",
                      "name": "McDonough School of Business",
                      "sameAs": [
                          "https://msb.georgetown.edu/",
                          "https://en.wikipedia.org/wiki/McDonough_School_of_Business",
                          "https://www.wikidata.org/entity/Q3303635"
                      ]
                  }
              ],
              "memberOf": [
                  {
                      "@type": "Organization",
                      "name": "New York University",
                      "sameAs": [
                          "https://www.nyu.edu",
                          "https://en.wikipedia.org/wiki/New_York_University",
                          "https://www.wikidata.org/wiki/Q49210"
                      ]
                  },
                  {
                      "@type": "Organization",
                      "name": "Georgetown University",
                      "sameAs": [
                          "https://www.georgetown.edu",
                          "https://en.wikipedia.org/wiki/Georgetown_University",
                          "https://www.wikidata.org/entity/Q333886"
                      ]
                  },
                  {
                      "@type": "Organization",
                      "name": "McDonough School of Business",
                      "sameAs": [
                          "https://msb.georgetown.edu/",
                          "https://en.wikipedia.org/wiki/McDonough_School_of_Business",
                          "https://www.wikidata.org/entity/Q3303635"
                      ]
                  }
              ],
              "hasCredential": [
                  {
                      "@type": "EducationalOccupationalCredential",
                      "credentialCategory": "Master's Degree",
                      "recognizedBy": {
                      "@type": "Organization",
                      "name": "Georgetown University",
                      "sameAs": [
                        "https://www.georgetown.edu",
                        "https://en.wikipedia.org/wiki/Georgetown_University",
                        "https://www.wikidata.org/entity/Q333886"
                        ]
                      }
                  },
                  {
                      "@type": "EducationalOccupationalCredential",
                      "credentialCategory": "Bachelor's Degree",
                      "recognizedBy": {
                      "@type": "Organization",
                      "name": "New York University",
                      "sameAs": [
                        "https://www.nyu.edu",
                        "https://en.wikipedia.org/wiki/New_York_University",
                        "https://www.wikidata.org/wiki/Q49210"
                    ]
                      }
                  }
              ]
          }
        `}
      </script>
    </Helmet>
  );
};

export default Seo;

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

const query = graphql`
  query Seo {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`;
